<template>
	<app background="#fff">
		<project-edit ref="projectEdit"></project-edit>
		
		<button-list-m slot="foot">
			<c-button @click="submit">确认并提交</c-button>
		</button-list-m>
	</app>
</template>

<script>
	import projectEdit from './project_edit_form.vue'
	
	export default {
		components: {
			projectEdit
		},
		
		props: {
			id: String
		},
		
		mounted() {
			if (this.id) {
				this.$refs.projectEdit.edit(this.id);
			} else {
				this.$refs.projectEdit.add();
			}
		},
		
		methods: {
			submit() {
				this.$refs.projectEdit.submit().then(() => {
					this.$message({
						text: '提交成功',
						resolve: () => {
							this.navigateTo('/project_list');
						}
					});
				});
			}
		}
	};
</script>

<style lang="stylus" scoped>
</style>