<template>
	<div>
		<c-hint>温馨提示：标星(*)的为必填项哦，招标代理也可代业主完成项目创建工作，代理成功创建项目后，您单位只需在系统中确认即可</c-hint>
		<c-form ref="form">
			<c-form-item type="hidden" name="project_id"></c-form-item>
			<c-form-item type="input" label="项目编号" name="code" placeholder="自动生成" required disabled></c-form-item>
			<c-form-item type="input" label="项目名称" name="name" required @change="validName"></c-form-item>
			<c-form-item type="select" label="所属行业" name="sshy" required options="INDUSTRY"></c-form-item>
			<c-form-item type="region" label="项目建设地址" province-name="province" city-name="city" county-name="district" address-name="address" required></c-form-item>
			<c-form-item type="file" label="招标文件" name="files_path" required file-type="word,excel,pdf,zip,image"></c-form-item>
			<c-form-item type="input" label="项目分管领导" name="yzdw_fgld_name" required></c-form-item>
			<c-form-item type="input" label="业主单位联系人" name="yzdw_lxr"></c-form-item>
			<c-form-item type="input" label="业主单位联系电话" name="yzdw_lxdh"></c-form-item>
			<c-form-item type="textarea" label="备注说明" name="remark"></c-form-item>
		</c-form>
	</div>
</template>

<script>
	export default {
		methods: {
			add() {
				this._edit = false;
				this.$refs.form.clear();
			},
			
			edit(id) {
				this._edit = true;
				this.request({
					url: '/project/detail',
					data: {
						project_id: id
					},
					success: data => {
						this.$refs.form.set({
							project_id: data.project_info.id
						});
						this.$refs.form.set(data.project_info);
					}
				});
			},
			
			validName(name) {
				this.request({
					url: '/project/existsName',
					data: {
						project_id: 0,
						name
					},
					fail: () => {
						this.$refs.form.set({
							name: ''
						});
					}
				});
			},
			
			submit() {
				return new Promise(resolve => {
					this.$refs.form.submit({
						url: this._edit ? '/project/update' : '/project/add',
						
						rule: {
							name: '请填写项目名称',
							sshy: '请选择所属行业',
							district: '请选择项目建设地址',
							address: '请填写项目建设详细地址',
							files_path: '请上传招标文件',
							yzdw_fgld_name: '请填写项目分管领导'
						},
						
						success: resolve
					});
				})
			}
		}
	};
</script>

<style lang="stylus" scoped>
</style>